body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Inter, Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
}


.grecaptcha-badge {
  visibility: hidden;
}

@import '@blueprintjs/core/lib/css/blueprint.css';