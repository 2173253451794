body,
html {
  padding: 0;
  margin: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

hr {
  height: 1px;
  background: rgba(218, 216, 214, 1); /*Colors.Gray200*/
  width: 100%;
  border: none;
}